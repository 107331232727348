import React from "react";
import Container from "../Container";
import { ColumnIcon, ShareIcon, TaxIcon } from "../Icons";
import Heading, { Gradient } from "../Typography/Heading";
import Text from "../Typography/Text";

export default function EcoSystem() {
  return (
    <Container
      id="ECOSYSTEM"
      className="eco flex flex-col items-center gap-8 lg:flex-row relative  "
    >
      <div className="w-full lg:w-1/2">
      <p className="txt !text-left">UTILITIES</p>

        <Heading className="text-7xl leading-tight !text-left" >
        Empowering Decentralized <br />
        Advertising with  <br />dOpus
        </Heading>
        <div className="space-y-4 mt-8 text-center lg:text-left">

        <Text >
          dOpus offers a range of utilities designed to make decentralized advertising simple and effective.
           With <strong>Flexible Ad Placement</strong>, users can choose where and how to advertise—whether on their
            car, home, or any personal space—allowing them to match options to their lifestyle and maximize earnings.
            <br />
        </Text>
        </div>
        <div className="flex gap-8 mt-20 flex-col md:flex-row justify-center lg:flex-row gap-y-20 ">
           {/*<button className="util-btn">See More</button>*/}
           {/*<button className="btnIn" >See More</button>*/}

        </div>
      </div>
      
      <div className="flex gap-10 mt-48 flex-col gap-y-20 md:flex-row " >
        <Card1 className="card1">
          <strong>Flexible Ad Placement</strong>
          <p>Advertise anywhere, maximize your earnings.</p>
        </Card1>
        <Card2 icon={<ShareIcon />}>
          <strong>Smart<br />Contracts</strong>
          <p>Automated, fair, and secure agreements.</p>
        </Card2>
        <Card3 icon={<ColumnIcon />}>
          <strong>Proof of <br /> Placement</strong>
          <p>Verified ads with photos and location.</p>
        </Card3>
      </div>
    </Container>
  );
}
const Card1 = ({ children }) => {
  return (
    <div className="card1 p-6 bg-[#272534] border-l border-l-[#C2CFE8] flex flex-col items-center rounded-xl">
      <div className="flex items-center mb-4">
        <img className="h-10 w-10 mr-4" src="/assets/check.png" alt="" />
        <div className="text-white text-xl">{children[0]}</div>
      </div>
      <div className="text-white text-center">{children[1]}</div>
    </div>
  );
};

const Card2 = ({ children }) => {
  return (
    <div className="card2 p-6 bg-[#272534] border-l border-l-[#C2CFE8] flex flex-col items-center rounded-xl">
      <div className="flex items-center mb-4">
        <img className="h-10 w-10 mr-4" src="/assets/Moneymanagement.png" alt="" />
        <div className="text-white text-xl">{children[0]}</div>
      </div>
      <div className="text-white text-center">{children[1]}</div>
    </div>
  );
};

const Card3 = ({ children }) => {
  return (
    <div className="card3 p-6 bg-[#272534] border-l border-l-[#C2CFE8] flex flex-col items-center rounded-xl">
      <div className="flex items-center mb-4">
        <img className="h-10 w-10 mr-4" src="/assets/Arrow.png" alt="" />
        <div className="text-white text-xl">{children[0]}</div>
      </div>
      <div className="text-white text-center">{children[1]}</div>
    </div>
  );
};
