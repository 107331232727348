import React from "react";
import Container from "../Container";
import Heading from "../Typography/Heading";
import Text from "../Typography/Text";
import Timeline from "./Timeline";

export default function RoadMap() {
  return (
    <Container id="ROADMAP" className="my-20 mt-20">
      <Heading className="text-center text-5xl">
        <span className="text-app-primary">Roadmap</span> Expansion Plans
      </Heading>
      <div className="flex justify-center mt-40">
        <RoadMapComponent />
      </div>
    </Container>
  );
}

function RoadMapComponent() {
  return (
    <ul className="">
      <Timeline title="Q3 2023" description="Project Kickoff and Initial Development">
        <p><Text color="#ffffff">- Genesis: Release of the foundational whitepaper</Text></p>
        <p><Text color="#ffffff">- Inception of the core development team</Text></p>
        <p><Text color="#ffffff">- The foundation of system architecture design</Text></p>
        <p><Text color="#ffffff">- Beginning of strategic investor meetings and pitch sessions</Text></p>
      </Timeline>

      <Timeline title="Q4 2023" description="Proof of Concept and Architecture Design">
        <p><Text color="#ffffff">- POC development</Text></p>
        <p><Text color="#ffffff">- Core contracts architecture design</Text></p>
      </Timeline>

      <Timeline title="Q1 2024" description="Core Development and Security Testing">
        <p><Text color="#ffffff">- Core contracts development</Text></p>
        <p><Text color="#ffffff">- Contracts security stress tests</Text></p>
      </Timeline>

      <Timeline title="Q2 2024" description="Platform Development and Beta Testing">
        <p><Text color="#ffffff">- Website/Platform development</Text></p>
        <p><Text color="#ffffff">- Expand development team</Text></p>
        <p><Text color="#ffffff">- Improve system tests</Text></p>
        <p><Text color="#ffffff">- Start closed beta testing for Core system</Text></p>
      </Timeline>

      <Timeline title="Q3 2024" description="Demo Release and Initial Community Engagement">
        <p><Text color="#ffffff">- Finalize platform development for demo</Text></p>
        <p><Text color="#ffffff">- Improve development process with CI/CD</Text></p>
        <p><Text color="#ffffff">- Investor and collaborator meetups</Text></p>
        <p><Text color="#ffffff">- Initial seed raise</Text></p>
        <p><Text color="#ffffff">- Create social media presence</Text></p>
      </Timeline>

      <Timeline title="Q4 2024" description="Market Pivot and ICO Launch">
        <p><Text color="#ffffff">- Pivot platform to specialized market (ads)</Text></p>
        <p><Text color="#ffffff">- Derive pivot system from existing Core system</Text></p>
        <p><Text color="#ffffff">- Start marketing promotions</Text></p>
        <p><Text color="#ffffff">- ICO</Text></p>
      </Timeline>

      <Timeline title="Q1 2025" description="Ads Platform Beta Testing and User Acquisition">
        <p><Text color="#ffffff">- Closed beta tests for ads platform</Text></p>
        <p><Text color="#ffffff">- Start ad campaigns to get users</Text></p>
        <p><Text color="#ffffff">- List dOpus ad campaigns on platform for users</Text></p>
        <p><Text color="#ffffff">- Add tokens as payment (not only network native token)</Text></p>
        <p><Text color="#ffffff">- Go-Live!</Text></p>
      </Timeline>

      <Timeline title="Q2 2025" description="Referral Programs and Governance Implementation">
        <p><Text color="#ffffff">- Develop referral program and revenue sharing</Text></p>
        <p><Text color="#ffffff">- Work with influencers as part of marketing</Text></p>
        <p><Text color="#ffffff">- Develop DAO and revenue-sharing dispute resolution system</Text></p>
        <p><Text color="#ffffff">- Expand development team</Text></p>
      </Timeline>

      <Timeline title="Q3 2025" description="System Improvements and Feature Expansion">
        <p><Text color="#ffffff">- Improve system (contract rollout, review, and dispute resolution)</Text></p>
        <p><Text color="#ffffff">- Develop 'Accept Offers' feature</Text></p>
        <p><Text color="#ffffff">- Expand cross-chain support</Text></p>
      </Timeline>

      <Timeline title="Q4 2025" description="Payment and User Trust Enhancements">
        <p><Text color="#ffffff">- Develop monthly payment system</Text></p>
        <p><Text color="#ffffff">- Develop credibility rating system for users</Text></p>
      </Timeline>
    </ul>
  );
}
