import React from "react";
import Container from "../Container";
import Heading from "../Typography/Heading";
import Text from "../Typography/Text";

export default function PreICO() {
  return (
    <Container
      id="PREICO"
      className="about flex flex-col items-center gap-8 lg:flex-row relative"
    >
      {/* Image Container */}
      <div className="w-full lg:w-1/2 flex justify-center items-center"> {/* Changed items-start to items-center */}
        <div className="box2 w-full max-w-lg"> {/* Added max-w-lg for better control over the image size */}
          <img 
            src="/assets/images/presale/presale3.jpg"
            alt="Why Us" 
            className="rounded-lg w-full h-auto" 
          />
        </div>
      </div>

      {/* Text Container */}
      <div className="w-full lg:w-1/2 flex flex-col justify-start">
        <p className="txt">Presale</p>
        <Heading className="text-7xl leading-tight !text-left">
          Fueling the Future of Decentralized Advertising<br />
        </Heading>
        <div className="space-y-4 mt-8 text-center lg:text-left">
          <Text>
            The <strong>dOpus token presale</strong> marks a pivotal step in our journey to revolutionize the advertising landscape through decentralization. As we prepare to launch our platform in <strong>Q1 2025</strong>, this presale will enable us to expand our development team and accelerate the creation of key features that will empower users to monetize their personal spaces like never before.<br/>
          </Text>
          <Text>
            By participating in the presale, early supporters not only gain access to the dOpus ecosystem at a foundational level but also play a critical role in shaping the future of decentralized advertising.
          </Text>
          <Text>
            The funds raised from the presale will be directly invested in growing our team with top-tier talent, ensuring we deliver a robust, secure, and user-friendly platform.
          </Text>
          <Text>
            With a strengthened development team, we aim to fast-track the implementation of innovative tools and smart contract functionalities that will enhance the dOpus experience.<br/>
          </Text>
          <Text>
            Join us in this exciting phase and be part of a movement that is set to redefine how advertising is done in a decentralized world.
          </Text>
        </div>
      </div>
    </Container>
  );
}
