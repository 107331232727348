import React from "react";
import Container from "../Container";
import Heading, { Gradient } from "../Typography/Heading";
import Text from "../Typography/Text";
import { toast, ToastContainer, ToastOptions, Id } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const toastId = "unique-toast-id"; 

export default function Tokenomics() {
  const data = [
    { amount: "500M", text: "Total Supply", info: "This is the total supply of tokens available." },
    { amount: "175M", text: "Locked development and ecosystem", info: "At TGE, 25% of tokens unlock with a 10-month vesting schedule. The remaining 75% are locked for 9 months, then 10% become convertible monthly over the next 10 months." },
    { amount: "50M", text: "Allocated to presale", info: "75% of tokens vest monthly over 10 months. The remaining 25% are locked for 12 months, then released at 10% per month over the next 10 months." },
    { amount: "100M", text: "Allocated to public sale", info: "At TGE, 50% of tokens unlock, with the rest vested over 8 months at 10% per month. This ensures liquidity while supporting long-term price stability." },
    { amount: "50M", text: "Team Locked", info: "At TGE, all tokens are locked for 24 months. Afterward, 10% of the tokens become convertible each month over the next 10 months." },
    { amount: "125M", text: "Allocated for liquidity", info: "All tokens are locked for 12 months, followed by a 10-month linear vesting schedule." },
  ];

  const handleInfoClick = (info) => {
    if (!toast.isActive(toastId)) {
      toast.info(info, {
        toastId, 
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      toast.update(toastId, {
        render: info,  // Update the existing toast content
      });
    }
  };

  return (
    <Container id="TOKENOMICS" className="mt-20">
      <Heading className="text-center text-6xl">Tokenomics</Heading>
      <div className="relative mt-20">
        {/* Hide the image on small screens */}
        <img className="hidden md:block w-full" src="/assets/images/world.png" alt="World" />
        
        {/* Remove absolute positioning on small screens */}
        <div className="w-full h-full md:absolute inset-0 flex flex-col md:flex-row justify-between md:pt-28 gap-2 xl:gap-20">
          {data.map((d) => (
            <div
              key={d.text}
              className="flex flex-col transform even:translate-y-0 md:even:translate-y-40 items-center gap-4 w-full md:w-96 text-center"
            >
              <h1 className="text-2xl font-bold lg:text-5xl">
                <Gradient>{d.amount}</Gradient>
              </h1>
              <Text>
                {d.text}
                <button
                  onClick={() => handleInfoClick(d.info)}
                  className="ml-2 text-blue-500"
                >
                  ⓘ
                </button>
              </Text>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </Container>
  );
}
