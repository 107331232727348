import Tokenomics from "../components/Tokenomics";
import Footer from "../components/Layout/Footer";
import Partner from "../components/Partners";
import Roadmap from "../components/RoadMap";
import Team from "../components/Team";
import Introduction from "../components/Introduction";
import NavBar from "../components/Layout/NavBar";
import EcoSystem from "../components/EcoSystem";
import Benefits from "../components/Benefits";
import PreICO from "../components/PreICO";
import ContactUs from "../components/Contact";
import { Helmet } from 'react-helmet';

export default function Home() {
  return (
    <>
      <NavBar />
      <Introduction />
      <Benefits />
      <EcoSystem />
      <Roadmap />
      <PreICO/>
      <Tokenomics />
      <ContactUs />
      <Footer />
      <Helmet>
         <script id="chatway" src="https://cdn.chatway.app/widget.js?id=lspFR9MzXnY0" async></script>
      </Helmet>
    </>
  );
}
