import React, { useState, useEffect } from "react";
import Container from "../Container";
import Heading from "../Typography/Heading";
import './styles.css';

//Firebase
import { db } from "../../firebase/firebase"; 
import { collection, addDoc } from "firebase/firestore";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    surname: "",
    email: "",
    subject: "",
    message: ""
  });

  const [loading, setLoading] = useState(false);
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    const detectMetadata = () => {
      // Detect platform
      const platform = navigator.platform;

      // Detect user agent (for additional browser/platform info)
      const userAgent = navigator.userAgent;

      // Detect language (can be used to infer country/region)
      const language = navigator.language;

      setMetadata({
        platform,
        userAgent,
        language,
      });
    };

    detectMetadata();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addDoc(collection(db, "contacts"), {
        ...formData,
        ...metadata,  // Add metadata to the Firestore document
        timestamp: new Date(),
      });
      alert("Message sent successfully!");
      setFormData({
        firstName: "",
        surname: "",
        email: "",
        subject: "",
        message: ""
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("There was an error sending your message. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <Container
      id="CONTACT"
      className="contact flex flex-col items-center gap-8 lg:flex-row relative mt-20"
    >
      <div className="w-[800px] h-[800px] bg-[#4E34EE]/10 filter blur-3xl absolute top-0 left-0 rounded-full transform -translate-x-2/3 -translate-y-20 z-0" />
      <div className="w-full relative z-10">
        <p className="txt">Contact Us</p>
        <Heading className="text-7xl leading-tight !text-left">
          Get in Touch<br />
        </Heading>
        <div className="space-y-4 mt-8 text-center lg:text-left">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="text-white">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full p-4 mt-2 bg-white/10 rounded-lg"
                  placeholder="Your First Name"
                  required
                />
              </div>
              <div>
                <label className="text-white">Surname</label>
                <input
                  type="text"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                  className="w-full p-4 mt-2 bg-white/10 rounded-lg"
                  placeholder="Your Surname"
                  required
                />
              </div>
              <div className="col-span-1 md:col-span-2">
                <label className="text-white">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-4 mt-2 bg-white/10 rounded-lg"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="col-span-1 md:col-span-2">
                <label className="text-white">Subject</label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full p-4 mt-2 bg-white/10 rounded-lg"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="col-span-1 md:col-span-2">
                <label className="text-white">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-4 mt-2 bg-white/10 rounded-lg h-32"
                  placeholder="Your Message"
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="btnIn mt-8 w-full md:w-auto p-4 rounded-lg"
              disabled={loading}
            >
              {loading ? "Sending..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
}